import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/announcing-cron-workflows",
  "date": "13 December 2023",
  "title": "Announcing Cron Workflows",
  "summary": "Exploring Skpr's new cron management features!",
  "author": "Karl Hepworth",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "security"
  }, {
    "name": "cron"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We're pleased to announce the release of Cron Workflows.`}</p>
    <p>{`As of release v0.24.0, you can now achieve the following:`}</p>
    <ul>
      <li parentName="ul">{`Suspend, resume, and list Cron jobs associated with an environment.`}</li>
      <li parentName="ul">{`List jobs executed by environment Cron jobs.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/graphic-terminal-cron-workflow.gif",
        "alt": "Terminal output showing the workflow of cron management via a terminal"
      }}></img></p>
    <h2>{`The technical benefits`}</h2>
    <h3>{`More information in your hands`}</h3>
    <p>{`Transparency for users who can now determine which cronjobs were run, if they
completed successfully and when they were last run.`}</p>
    <h3>{`Mitigating risk on large applications`}</h3>
    <p>{`Bigger applications with regular cron jobs increase the probability of writing
to the database during a major upgrade. Being able to temporarily disable
these workloads helps mitigate this risk.`}</p>
    <h3>{`Script-friendly`}</h3>
    <p>{`Listing CronJobs and Jobs also supports JSON output formatting.
Information can be stored, enumerated and used programmatically if you need.
It can also be processed using tools such as `}<inlineCode parentName="p">{`jq`}</inlineCode>{`.`}</p>
    <h2>{`CI Integrations`}</h2>
    <p>{`Support for `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/cron/github_actions/"
      }}>{`GitHub Actions`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/cron/circleci_orb/"
      }}>{`CircleCI`}</a>{` has also been
added. Check out our documentation for all the details.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`This will be rolling out to all customers as the default for
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/cron/github_actions/"
      }}>{`GitHub Actions`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/cron/circleci_orb/"
      }}>{`CircleCI`}</a>{` integrations
in a future release. For now, you can test this capability out using the
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/cron/github_actions/"
      }}>{`feature flag`}</a>{` outlined
in our docs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      